var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-tabs',{attrs:{"align":"center","pills":""}},[_c('b-card',[_c('b-tab',{attrs:{"lazy":"","title":"Add Revenues","active":_vm.tabIndex == 0 ? true : false},on:{"click":function($event){_vm.onTabClick((_vm.tabIndex = 0))}}},[_c('b-card-text',[_c('UploadRevenuePage')],1)],1),_c('b-tab',{attrs:{"lazy":"","title":"View Added Revenues","active":_vm.tabIndex == 1 ? true : false},on:{"click":function($event){_vm.onTabClick((_vm.tabIndex = 1))}}},[_c('b-card-text',[(_vm.tabIndex == 1)?_c('div',{class:_vm.tabIndex == 1
                  ? 'tab-pane fade rounded bg-white show active'
                  : 'tab-pane fade rounded bg-white',attrs:{"role":"tabpanel","aria-labelledby":"v-pills-home-tab"}},[_c('ViewAddedRevenuePage')],1):_vm._e()])],1),_c('b-tab',{attrs:{"lazy":"","title":"Add Expenses","active":_vm.tabIndex == 2 ? true : false},on:{"click":function($event){_vm.onTabClick((_vm.tabIndex = 2))}}},[_c('b-card-text',[(_vm.tabIndex == 2)?_c('div',{class:_vm.tabIndex == 2
                  ? 'tab-pane fade  rounded bg-white show active'
                  : 'tab-pane fade  rounded bg-white',attrs:{"role":"tabpanel","aria-labelledby":"v-pills-home-tab"}},[_c('AddExpensePage')],1):_vm._e()])],1),_c('b-tab',{attrs:{"lazy":"","title":"View Added Expenses","active":_vm.tabIndex == 3 ? true : false},on:{"click":function($event){_vm.onTabClick((_vm.tabIndex = 3))}}},[_c('b-card-text',[(_vm.tabIndex == 3)?_c('div',{class:_vm.tabIndex == 3
                  ? 'tab-pane fade  rounded bg-white show active'
                  : 'tab-pane fade  rounded bg-white',attrs:{"role":"tabpanel","aria-labelledby":"v-pills-home-tab"}},[_c('ViewAddedExpensePage')],1):_vm._e()])],1),_c('b-tab',{attrs:{"lazy":"","title":"Create Invoices","active":_vm.tabIndex == 4 ? true : false},on:{"click":function($event){_vm.onTabClick((_vm.tabIndex = 4))}}},[_c('b-card-text',[(_vm.tabIndex == 4)?_c('div',{class:_vm.tabIndex == 4
                  ? 'tab-pane fade  rounded bg-white show active'
                  : 'tab-pane fade  rounded bg-white',attrs:{"role":"tabpanel","aria-labelledby":"v-pills-home-tab"}},[_c('b-tabs',{attrs:{"active-nav-item-class":"font-weight-bold text-uppercase text-primary","active-tab-class":"font-weight-bold text-success","content-class":"mt-1"}},[_c('b-tab',{attrs:{"lazy":"","title":" Create Normal Invoice","active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"18"}}),_vm._v(" Create Normal Invoice ")]},proxy:true}],null,false,315495336)},[_c('CreateNormalInvoicePage')],1),_c('b-tab',{attrs:{"lazy":"","title":"Manual Invoice"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"18"}}),_vm._v("Create Manual Invoice ")]},proxy:true}],null,false,3435708161)},[_c('CreateManualInvoicePage')],1)],1)],1):_vm._e()])],1),_c('b-tab',{attrs:{"lazy":"","title":"Invoice & Payments","active":_vm.tabIndex == 5 ? true : false},on:{"click":function($event){_vm.onTabClick((_vm.tabIndex = 5))}}},[_c('b-card-text',[(_vm.tabIndex == 5)?_c('div',{class:_vm.tabIndex == 5
                  ? 'tab-pane fade  rounded bg-white show active'
                  : 'tab-pane fade  rounded bg-white',attrs:{"role":"tabpanel","aria-labelledby":"v-pills-home-tab"}},[_c('InvoicePaymentPage')],1):_vm._e()])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }