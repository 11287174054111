<template>
  <div v-if="$can('create', 'expense')">
    <b-card-group deck>
      <b-card border-variant="primary">
        <b-card-text class="mt-2">
          <b-row>
            <b-col md="3">
              <b-form-group label="Revenue Date">
                <flat-pickr
                  :config="{ ...$flatPickrConfig }"
                  v-model="expenseDate"
                  placeholder="Revenue Date"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group label="Movie">
                <v-select
                  @input="removeRightsData"
                  v-model="selectedMovie"
                  placeholder="Movie"
                  label="full_title"
                  :options="optionMovies"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Licensor">
                <v-select
                  :disabled="!selectedMovie ? true : false"
                  v-model="selectedCustomer"
                  placeholder="Licensor"
                  label="company"
                  :options="optionCustomer"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-2 d-flex justify-content-center">
              <!-- ||
                  !this.selectedRight -->
              <b-button
                :disabled="
                  !this.selectedCustomer ||
                  !this.selectedMovie ||
                  !this.expenseDate
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1 md-mb-0"
                @click="getReportContracts"
              >
                <b-spinner v-if="loading" small /> Fetch Rights
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="mb-1 md-mb-0"
                @click="resetForm"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>

          <b-alert variant="dark" v-if="noDataError == true" show>
            <div class="alert-body">
              <span>
                <!-- {{
                  selectedRight.value == "1" ? "THEATRICAL " : "OTHER "
                }} -->
                RIGHTS not found for movie {{ selectedMovie.title }} ..!</span
              >
            </div>
          </b-alert>
          <b-card
            border-variant="success"
            bg-variant="light"
            class="text-center"
            v-if="optionRevenueRights.length > 0"
          >
            <b-card-text>
              <b-row
                v-for="(subRight, index) in optionRevenueRights"
                :key="index"
              >
                <b-col md="1">
                  <b-form-group :label="index == 0 ? 'Sr.No' : ''">
                    <b-form-input
                      disabled
                      id="from"
                      :value="index + 1"
                      placeholder="Sub Right"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group :label="index == 0 ? 'Sub Right' : ''">
                    <b-form-input
                      disabled
                      id="from"
                      :value="subRight.lbl_id"
                      v-model="subRight.lbl_name"
                      placeholder="Sub Right"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group
                    :label="index == 0 ? 'Revenue Amount (CHF)' : ''"
                  >
                    <b-form-input
                      id="from"
                      v-model="subRight.revenueAmount"
                      placeholder="Revenue Amount"
                      type="number"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group :label="index == 0 ? 'Create Invoice ?' : ''">
                    <b-form-checkbox
                      disabled
                      v-model="subRight.is_billed"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-col> </b-row
            ></b-card-text>
          </b-card>

          <b-row class="mt-2" v-if="optionRevenueRights.length > 0">
            <b-col md="4"> </b-col>
            <b-col md="4">
              <!-- ||
                  !this.selectedRight -->
              <b-button
                :disabled="
                  !this.selectedCustomer ||
                  !this.selectedMovie ||
                  !this.expenseDate
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                block
                variant="primary"
                class="mr-1 mb-1 md-mb-0"
                @click="addManualRevenueData"
              >
                <b-spinner v-if="loading" small /> Submit Revenue Data
              </b-button>
            </b-col>
            <b-col md="4"> </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-card-group>
    <div>
      <b-modal
        scrollable
        v-model="modalContract"
        id="modalContract"
        size="xl"
        hide-footer
      >
        <b-table
          bordered
          class="position-relative"
          primary-key="contract_id"
          responsive
          show-empty
          :items="contractsList"
          :fields="fieldsContract"
          hover
        >
          <!-- hover -->
          <template #cell(contract_id)="data">
            {{ data.item.contract_id }}
          </template>
          <template #cell(company)="data">
            <div style="width: max-content">
              <b-badge variant="light-success">
                {{ data.item.contract_nature
                }}{{ data.item.draft ? "(draft)" : "" }}</b-badge
              ><br />{{ data.item.company }}
            </div>
          </template>
          <template #cell(title)="data">
            <div style="width: max-content" v-html="data.value"></div>
          </template>
          <template #cell(territory)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(right)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(language)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(purchaseDate)="data">
            <b-badge variant="light-info"
              >Contract Date : {{ data.item.purchaseDate }} </b-badge
            ><br />
            <b-badge variant="light-success" class="mt-1">
              Start : {{ data.item.rStart }} </b-badge
            ><br />

            <b-badge variant="light-danger" class="mt-1">
              End : {{ data.item.rExpiry }}</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                variant="primary"
                size="sm"
                @click="GetDataManualRevenue(data.item.contract_id)"
              >
                Fetch Rights
              </b-button>
            </div>
          </template>
          <template #cell()="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BBadge,
  VBToggle,
  BSpinner,
  BCardGroup,
  BModal,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import * as XLSX from "xlsx";

import { getAllMovie } from "@/apiServices/MovieServices";

import {
  AddManualRevenueData,
  getDataManualRevenue,
  GetMoviesVendor,
} from "@/apiServices/RevenueServies";
import * as ContractServices from "@/apiServices/ContractServices";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { downloadFromURL } from "@/utils/helpers";

export default {
  components: {
    BCard,
    BCardGroup,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    vSelect,
    flatPickr,
    XLSX,
    ToastificationContentVue,
    AppCollapseItem,
    AppCollapse,
    VBToggle,
    BSpinner,
    BModal,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fieldsContract: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },

        {
          key: "company",
          label: "Contact",
        },
        {
          key: "title",
          label: "Movie",
        },
        {
          key: "territory",
          label: "territory",
        },
        {
          key: "right",
          label: "rights",
        },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "purchaseDate",
          label: "contract dates",
        },

        {
          key: "language",
          label: "languages",
        },
        { key: "contract_id", label: "Contract ID" },
      ],
      showSuccess: false,
      modalContract: false,
      contractID: null,
      insertedDataCount: null,
      showErrorData: false,
      errorExcelData: null,
      BASE_URL: process.env.VUE_APP_BASEURL,
      expenseDate: null,

      selectedCustomer: null,
      loading: false,
      noDataError: false,
      optionCustomer: [],
      allTeritory: [],
      optionRevenueRights: [],
      selectedRight: null,
      expenseAmount: null,
      optionRights: [
        { title: "Theatrical", value: 1 },
        { title: "Other Rights", value: 2 },
      ],
      optionMovies: [],
      allRights: [],
      contractsList: [],
      selectedMovie: null,
      optionTeritory: [],
      selectedTeritory: null,
      uploaded_documents: null,
      optionAvailableTeritory: [],
      optionAvailablerights: [],
    };
  },

  computed: {},
  watch: {},

  methods: {
    async removeRightsData() {
      this.noDataError = false;
      this.optionTeritory = [];
      this.optionRevenueRights = [];
      this.selectedTeritory = null;
      this.selectedCustomer = null;
      this.GetAllVendor();
    },

    async getReportContracts() {
      try {
        const response = await ContractServices.getAllContract({
          movie:
            this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          vendor: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : null,
          contract_nature: "Acquisition",
        });
        this.contractsList = response.data.data.contracts;

        if (!response.data.data.contracts.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Acquisition Contract Not Found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          if (this.contractsList.length > 1) {
            this.modalContract = true;
          } else {
            this.GetDataManualRevenue(this.contractsList[0].contract_id);
          }
        }
      } catch (error) {
        console.log("Error in getting Contracts List", error);
      }
    },
    async GetDataManualRevenue(contractid) {
      this.contractID = contractid;
      this.noDataError = false;
      this.optionTeritory = [];
      this.optionRevenueRights = [];
      this.selectedTeritory = null;
      if (!this.contractID) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Some Thing Went wrong",
            icon: "EditIcon",
            variant: "danger",
          },
        });
        return false;
      }
      try {
        let payload = {
          movie_id: this.selectedMovie.movie_id,
          vendor_id: this.selectedCustomer.vendor_id,
          expenseDate: this.expenseDate,
          contract_id: this.contractID,
        };
        const response = await getDataManualRevenue(payload);
        if (response.data) {
          response.data.data.map((p) => {
            if (
              p.lbl_value1 == "9334" ||
              p.lbl_name.toLowerCase() == "theatrical"
            ) {
              p["right_type"] = "1";
            } else {
              p["right_type"] = "2";
            }
          });
          this.optionRevenueRights = response.data.data;

          if (this.optionRevenueRights.length <= 0) {
            this.noDataError = true;
          } else {
            this.noDataError = false;
          }
          this.modalContract = false;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async addManualRevenueData() {
      this.loading = true;
      try {
        let payload = {
          vendor_id: this.selectedCustomer.vendor_id,
          movie_id: this.selectedMovie.movie_id,
          movie_isan: this.selectedMovie.isan_number,
          movie_unique: this.selectedMovie.unique,
          movie_title: this.selectedMovie.title,
          revenue_date: this.expenseDate,
          optionRevenueRights: this.optionRevenueRights,
          contract_id: this.contractID,
          right_type: null,
        };

        const response = await AddManualRevenueData(payload);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Added Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.resetForm();

          // this.optionCustomer = response.data.data.rows;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Something went wrong",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // this.resetForm();
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllVendor() {
      this.optionCustomer = [];
      try {
        const response = await GetMoviesVendor({
          movie_id: this.selectedMovie.movie_id,
        });
        if (response.data.status) {
          this.optionCustomer = response.data.data;

          if (this.optionCustomer.length == 1) {
            this.selectedCustomer = this.optionCustomer[0];
          }
        }
      } catch (error) {
        console.log("Error in getting Contracts List");
      }
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    downloadSample() {
      fetch(this.BASE_URL + "/revenue/expensesampledownload", {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          downloadFromURL(_url, "SampleExpense", "xlsx");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetForm() {
      this.selectedCustomer = null;
      this.selectedMovie = null;
      this.selectedTeritory = null;
      this.selectedRight = null;
      this.expenseAmount = null;
      this.expenseDate = null;

      this.optionRevenueRights = [];
    },

    downloadErrorExcel() {
      if (this.errorExcelData) {
        let data = this.errorExcelData;
        let sheetName = "Expense";
        let excelName = "ErrorExpenseExcel.xlsx";

        var dataFinal = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataFinal, sheetName);
        XLSX.writeFile(wb, excelName);
      }
    },
  },

  beforeMount() {
    this.GetAllMovie();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
