<template>
  <!-- Demo header-->
  <div>
    <div>
      <b-tabs align="center" pills>
        <b-card>
          <b-tab
            lazy
            @click="onTabClick((tabIndex = 0))"
            title="Add Revenues"
            :active="tabIndex == 0 ? true : false"
          >
            <b-card-text>
              <UploadRevenuePage />

              <!-- <div
              v-if="tabIndex == 0"
              :class="
                tabIndex == 0
                  ? 'tab-pane fade  rounded bg-white show active'
                  : 'tab-pane fade  rounded bg-white'
              "
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <b-tabs
                active-nav-item-class="font-weight-bold text-uppercase text-primary"
                active-tab-class="font-weight-bold text-success"
                content-class="mt-1"
              >
               
                <b-tab lazy title="Upload Revenue" active>
                  <template #title>
                    <feather-icon icon="FileIcon" size="18" />Upload Revenue
                  </template>
                </b-tab>
              </b-tabs>
            </div> -->
            </b-card-text>
          </b-tab>
          <b-tab
            lazy
            @click="onTabClick((tabIndex = 1))"
            title="View Added Revenues"
            :active="tabIndex == 1 ? true : false"
          >
            <b-card-text
              ><div
                v-if="tabIndex == 1"
                :class="
                  tabIndex == 1
                    ? 'tab-pane fade rounded bg-white show active'
                    : 'tab-pane fade rounded bg-white'
                "
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <ViewAddedRevenuePage /></div
            ></b-card-text>
          </b-tab>
          <b-tab
            lazy
            @click="onTabClick((tabIndex = 2))"
            title="Add Expenses"
            :active="tabIndex == 2 ? true : false"
          >
            <b-card-text>
              <div
                v-if="tabIndex == 2"
                :class="
                  tabIndex == 2
                    ? 'tab-pane fade  rounded bg-white show active'
                    : 'tab-pane fade  rounded bg-white'
                "
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <AddExpensePage /></div
            ></b-card-text>
          </b-tab>
          <b-tab
            lazy
            @click="onTabClick((tabIndex = 3))"
            title="View Added Expenses"
            :active="tabIndex == 3 ? true : false"
          >
            <b-card-text>
              <div
                v-if="tabIndex == 3"
                :class="
                  tabIndex == 3
                    ? 'tab-pane fade  rounded bg-white show active'
                    : 'tab-pane fade  rounded bg-white'
                "
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <ViewAddedExpensePage />
              </div>
            </b-card-text>
          </b-tab>
          <b-tab
            lazy
            @click="onTabClick((tabIndex = 4))"
            title="Create Invoices"
            :active="tabIndex == 4 ? true : false"
          >
            <b-card-text>
              <div
                v-if="tabIndex == 4"
                :class="
                  tabIndex == 4
                    ? 'tab-pane fade  rounded bg-white show active'
                    : 'tab-pane fade  rounded bg-white'
                "
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <b-tabs
                  active-nav-item-class="font-weight-bold text-uppercase text-primary"
                  active-tab-class="font-weight-bold text-success"
                  content-class="mt-1"
                >
                  <b-tab lazy title=" Create Normal Invoice" active>
                    <template #title>
                      <feather-icon icon="FileTextIcon" size="18" /> Create
                      Normal Invoice
                    </template>
                    <CreateNormalInvoicePage />
                  </b-tab>
                  <b-tab lazy title="Manual Invoice">
                    <template #title>
                      <feather-icon icon="FileTextIcon" size="18" />Create
                      Manual Invoice
                    </template>
                    <CreateManualInvoicePage />
                  </b-tab>
                </b-tabs></div
            ></b-card-text>
          </b-tab>
          <b-tab
            lazy
            @click="onTabClick((tabIndex = 5))"
            title="Invoice & Payments"
            :active="tabIndex == 5 ? true : false"
          >
            <b-card-text>
              <div
                v-if="tabIndex == 5"
                :class="
                  tabIndex == 5
                    ? 'tab-pane fade  rounded bg-white show active'
                    : 'tab-pane fade  rounded bg-white'
                "
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <InvoicePaymentPage /></div
            ></b-card-text>
          </b-tab>
        </b-card>
      </b-tabs>
    </div>

    <!-- old from here -->
    <!-- <b-row>
      <b-col cols="12" md="8">cols="12" md="8"</b-col>
      <b-col cols="6" md="4">cols="6" md="4"</b-col>
    </b-row> -->

    <!-- <b-tabs
      active-nav-item-class="font-weight-bold"
      active-tab-class="font-weight-bold"
      v-model="tabIndex"
      nav-wrapper-class="sticky-top col-md-2 col-12 overflow-y-hidden"
      content-class="col-12 col-md-10 mt-1 mt-md-0"
      pills
      nav-class="nav-left"
      vertical
    >
      <b-tab @click="onTabClick" title="Add Revenues"
        ><div v-if="tabIndex == 0">
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-primary"
            active-tab-class="font-weight-bold text-success"
            content-class="mt-1"
          >
            <b-tab title="Add Revenue" active>
              <AddRevenuePage />
            </b-tab>
            <b-tab title="Upload Revenue"> <UploadRevenuePage /></b-tab>
          </b-tabs></div
      ></b-tab>
      <b-tab @click="onTabClick" title="View Added Revenues"
        ><div v-if="tabIndex == 1"><ViewAddedRevenuePage /></div
      ></b-tab>
      <b-tab @click="onTabClick" title="Add Expenses"
        ><div v-if="tabIndex == 2"><AddExpensePage /></div
      ></b-tab>
      <b-tab @click="onTabClick" title="View Added Expenses"
        ><div v-if="tabIndex == 3"><ViewAddedExpensePage /></div
      ></b-tab>
      <b-tab @click="onTabClick" title="Create Invoices">
        <div v-if="tabIndex == 4">
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-primary"
            active-tab-class="font-weight-bold text-success"
            content-class="mt-1"
          >
            <b-tab title="Normal Invoice" active>
              <CreateNormalInvoicePage />
            </b-tab>
            <b-tab title="Manual Invoice"> <CreateManualInvoicePage /> </b-tab>
          </b-tabs>
        </div>
      </b-tab>
      <b-tab @click="onTabClick" title="Invoice Payments"
        ><div v-if="tabIndex == 5"><InvoicePaymentPage /></div
      ></b-tab>
    </b-tabs> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BBadge,
  BSpinner,
  BTabs,
  BTab,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import AddRevenuePage from "./RevenuePages/AddRevenuePage.vue";
import UploadRevenuePage from "./RevenuePages/UploadRevenuePage.vue";
import ViewAddedRevenuePage from "./RevenuePages/ViewAddedRevenuePage.vue";
import AddExpensePage from "./RevenuePages/AddExpensePage.vue";
import ViewAddedExpensePage from "./RevenuePages/ViewAddedExpensePage.vue";
import CreateManualInvoicePage from "./RevenuePages/CreateManualInvoicePage.vue";
import CreateNormalInvoicePage from "./RevenuePages/CreateNormalInvoicePage.vue";
import InvoicePaymentPage from "./RevenuePages/InvoicePaymentPage.vue";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    BSpinner,
    BTabs,
    BTab,
    BDropdownDivider,
    AddRevenuePage,
    ViewAddedRevenuePage,
    AddExpensePage,
    ViewAddedExpensePage,
    CreateManualInvoicePage,
    CreateNormalInvoicePage,
    InvoicePaymentPage,
    UploadRevenuePage,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      tabIndex: 0,
    };
  },

  methods: {
    onTabClick() {
      this.$nextTick(async () => {
        this.$router.push({
          name: "AddRevenueMain",
          query: { tabIndex: this.tabIndex },
        });
      });
    },
  },

  beforeMount() {
    if (this.$route.query.tabIndex) {
      this.tabIndex = Number(this.$route.query.tabIndex);
    } else {
      this.$router.push({
        name: "AddRevenueMain",
        query: { tabIndex: this.tabIndex },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-pills-custom .nav-link {
  background: #fff;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #fff;
  background: #1f307a !important;
}
[dir] .card-body {
  padding: 0rem !important;
}
</style>
